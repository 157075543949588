import React from 'react';
import Button from '../components/Button';

const NotFoundPage = () => (
  <div css={{ padding: 20, textAlign: 'center' }}>
    <span css={{ display: 'block', fontSize: 140 }} aria-label="Siden findes ikke" role="img">
      😱
    </span>
    <h1 css={{ marginBottom: 15 }}>Siden findes ikke</h1>
    <p>
      <span css={{ maxWidth: 400 }}>Vi kunne desværre ikke finde den side du søgte.</span>
    </p>
    <Button css={{ display: 'inline-block', marginTop: 20 }} to="/">
      Gå til forsiden
    </Button>
  </div>
);

export default NotFoundPage;
